import React from "react";
import "./Container.css";
// import { Routes, Route } from "react-router-dom";
import { Home, About, Contact, Works } from "../../views/exportViews";
import SocialMedia from "../../components/socialMedia/SocialMedia"
import { FloatingButton } from "../exportComponents";
const Container = () => {

  return (
    <>
      <div dir="auto" className="containers right">
        {/* <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/works" element={<Works />} />
          <Route path="/contact" element={<Contact />} />
        </Routes> */}
        <Home/>
        <About/>
        <Works/>
        <Contact />
        <SocialMedia />
        <FloatingButton/>
      </div>
    </>
  );
};

export default Container;
