import "./App.css";
// import { BrowserRouter } from "react-router-dom";
import { Header, Container, Footer } from "./components/exportComponents";
function App() {
  return (
    <>
      {/* <BrowserRouter> */}
      <Header />
      <Container />
      <Footer />
      {/* </BrowserRouter> */}
    </>
  );
}

export default App;
 