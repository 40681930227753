import React, { useState, useEffect } from "react";
import "./LampSwitch.css";
import { useTranslation } from "react-i18next";
import {
  logoWhite,
  logo,
  darkMode,
  lightMode,
} from "../../assets/images/exportImages";

const LampSwitch = () => {
  const { t } = useTranslation();
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Obtiene la preferencia del usuario del localStorage al montar el componente
    const darkMode = localStorage.getItem("dark-mode");
    if (darkMode === "true") {
      setIsDarkMode(true);
    }
  }, []); // Se ejecuta solo una vez al montar el componente

  const handleClick = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);

    // Actualiza el estado del modo oscuro en localStorage
    localStorage.setItem("dark-mode", newDarkMode.toString());
  };

  useEffect(() => {
    // Aplica el tema oscuro al cuerpo y a la barra de navegación al cambiar el estado
    if (isDarkMode) {
      document.documentElement.style.setProperty(
        "--bg-color-primary",
        "#121111"
      );
      document.documentElement.style.setProperty("--colo-primary", "#fff");
      document.body.classList.add("dark-mode");
      const navbarContainer = document.querySelector(".navbar-container");
      const cards = document.querySelectorAll(".info-card");
      const footer = document.querySelector(".footer");
      const navbarLogo = document.querySelector(".logo");

      const navMenu = document.querySelector(".navbar-menu");
      if (navbarContainer && cards && footer && navbarLogo) {
        navbarContainer.style.backgroundColor = "#121111"; // Cambia el color de fondo de la barra de navegación
        navMenu.style.backgroundColor = "#121111"; // Cambia el color de fondo del menú de navegación
        navbarContainer.classList.add("dark-mode");
        cards.forEach((card) => card.classList.add("dark-mode-cards"));
        footer.classList.add("dark-mode");
        navbarLogo.setAttribute("src", logoWhite);
      }
    } else {
      document.body.classList.remove("dark-mode");
      document.documentElement.style.setProperty("--bg-color-primary", "#fff");
      document.documentElement.style.setProperty("--colo-primary", "#333");
      const navbarContainer = document.querySelector(".navbar-container");
      const cards = document.querySelectorAll(".info-card");
      const footer = document.querySelector(".footer");
      const navbarLogo = document.querySelector(".logo");
      const navMenu = document.querySelector(".navbar-menu");

      if (navbarContainer && cards && footer && navbarLogo) {
        navbarContainer.style.backgroundColor = "#fff"; // Restaura el color de fondo de la barra de navegación
        navMenu.style.backgroundColor = "rgba(248, 248, 248, 0.9)"; // Restaura el color de fondo del menú de navegación
        navbarContainer.classList.remove("dark-mode");
        cards.forEach((card) => card.classList.remove("dark-mode-cards"));
        footer.classList.remove("dark-mode");
        navbarLogo.setAttribute("src", logo);
      }
    }
  }, [isDarkMode]); // Se ejecuta cada vez que cambia el estado de isDarkMode

  return (
    <div className="lamp-switch-container">
      <div className="lamp-switch" onClick={handleClick}>
        <img
          src={isDarkMode ? lightMode : darkMode}
          alt="lamp-mode"
          className="lamp-mode"
        />
      </div>
    </div>
  );
};

export default LampSwitch;
