import React from "react";
import pdfUrl from "../../assets/files/Bouazi_Ayoub_Cv.pdf";
import { cv } from "../../assets/images/exportImages";

const PdfViewer = () => {
  const openPdfInNewTab = () => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <div className="pdf-viewer-button">
      <div onClick={openPdfInNewTab}>
        <img src={cv} width={"65px"} alt="Logo CV" />
      </div>
    </div>
  );
};

export default PdfViewer;
