// https://dashboard.emailjs.com/admin
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import { useTranslation } from "react-i18next";

import "./Contact.css";

const Contact = () => {
  const { t } = useTranslation();

  const [userNameHasContent, setUserNameHasContent] = useState(false);
  const [userEmailHasContent, setUserEmailHasContent] = useState(false);
  const [messageHasContent, setMessageHasContent] = useState(false);
  const [subjectHasContent, setSubjectHasContent] = useState(false);

  const userNameRef = useRef(null); // Use null instead of an empty string
  const userEmailRef = useRef(null); // Use null instead of an empty string
  const messageRef = useRef(null); // Use null instead of an empty string
  const subjectRef = useRef(null); // Use null instead of an empty string

  // const errorMsg = useRef(null);
  const [errorMsg, setErrorMsg] = useState("");

  const [messageSent, setMessageSent] = useState("");

  const form = useRef(null); // Add form reference

  const handleInputBlur = (event) => {
    const { name, value } = event.target;

    if (name === "user_name") {
      setUserNameHasContent(!!value);
    } else if (name === "user_email") {
      setUserEmailHasContent(!!value);
    } else if (name === "message") {
      setMessageHasContent(!!value);
    } else if (name === "subject") {
      setSubjectHasContent(!!value);
    }
    // const { name, value } = event.target;
    // Remove 'empty' class from the label when the input loses focus and is not empty
    if (value) {
      event.target.parentElement
        .querySelector(".label")
        .classList.remove("empty");
    } else {
      // Agrega una clase 'empty' cuando el campo está vacío
      event.target.parentElement.querySelector(".label").classList.add("empty");
    }
  };

  const isEmailValid = (email) => {
    // Use a simple regular expression to validate the email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const sendEmail = (event) => {
    event.preventDefault();

    // Clear Messge sent
    setMessageSent("");

    const userName = userNameRef.current.value;
    const userEmail = userEmailRef.current.value;
    const message = messageRef.current.value;
    const subject = subjectRef.current.value;

    // Validate the form fields
    if (
      userName.trim() === "" ||
      userEmail.trim() === "" ||
      message.trim() === "" ||
      subject.trim() === ""
    ) {
      setErrorMsg(t(`contact.emptyfields`));
      return;
    }

    // Validate the email
    if (!isEmailValid(userEmail)) {
      setErrorMsg(t(`contact.invalidEmail`));

      return;
    }

    // If validation passes, you can perform additional logic, such as sending the data to a server
    // console.log({ userName, userEmail, message });

    emailjs
      .sendForm(
        "service_6prbown",
        "template_sy27mab",
        form.current,
        "pB7rPuYyT7xst6o1o"
      )
      .then(
        () => {
          // console.log(result.text);
          setMessageSent(t(`contact.messageSent`));
        },
        () => {
          setErrorMsg(t(`contact.errorMsg`));
        }
      );

    // Reset the form
    form.current.reset();

    // Clear the error message
    setErrorMsg("");
  };

  return (
    <>
      <div className="my-container content" id="contact">
        <section className="get-in-touch">
          <h1 className="title"> {t(`contact.title`)}</h1>
          <h2 className="subTitle"> {t(`contact.subtitle`)}</h2>
          <p className="description"> {t(`contact.description`)}</p>
          <form className="contact-form row" ref={form} onSubmit={sendEmail}>
            <div className="form-field col x-50">
              <input
                id="userName"
                className={`input-text js-input ${
                  userNameHasContent ? "not-empty" : ""
                }`}
                type="text"
                name="user_name"
                ref={userNameRef}
                onBlur={handleInputBlur}
              />
              <label className="label" htmlFor="userName">
                {t(`contact.name`)}
              </label>
            </div>
            <div className="form-field col x-50">
              <input
                id="userEmail"
                className={`input-text js-input ${
                  userEmailHasContent ? "not-empty" : ""
                }`}
                type="text"
                name="user_email"
                ref={userEmailRef}
                onBlur={handleInputBlur}
              />
              <label className="label" htmlFor="userEmail">
                {t(`contact.email`)}
              </label>
            </div>{" "}
            <div className="form-field col x-100">
              <input
                id="subject"
                className={`input-text js-input ${
                  subjectHasContent ? "not-empty" : ""
                }`}
                type="text"
                name="subject"
                ref={subjectRef}
                onBlur={handleInputBlur}
              />
              <label className="label" htmlFor="subject">
                {t(`contact.subject`)}
              </label>
            </div>
            <div className="form-field col x-100">
              <input
                id="message"
                className={`input-text js-input ${
                  messageHasContent ? "not-empty" : ""
                }`}
                type="text"
                name="message"
                ref={messageRef}
                onBlur={handleInputBlur}
              />
              <label className="label" htmlFor="message">
                {t(`contact.message`)}
              </label>
            </div>
            <div className="form-field col x-100 align-center">
              <input
                className="submit-btn"
                type="submit"
                value={t(`contact.send`)}
              />
              {/* Protection de datos */}
              {/* <p className="data-protection">
                {t(`contact.dataProtection`)}
                <a href="https://www.iubenda.com/privacy-policy/30486152">
                  {t(`contact.privacyPolicy`)}
                </a>
              </p> */}
            </div>
          </form>
      
          {messageSent && <p className="success-message">{messageSent}</p>}

          {errorMsg && <p className="error-message">{errorMsg}</p>}
        </section>
      </div>
    </>
  );
};

export default Contact;
