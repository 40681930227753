import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: localStorage.getItem("i18nextLng") || "en",
    fallbackLng: "en",
    resources: {
      es: {
        translation: {
          header: {
            language: "Es",
            es: "Español",
            en: "Inglés",
            cat: "Catalán",
            ar: "Árabe",
            fr: "Francés",
            home: "Inicio",
            aboutMe: "Acerca de mí",
            projects: "Proyectos",
            contact: "Contacto",
          },
          home: {
            title: "AYOUB BOUAZI",
            subtitle: "SOY: ",
            roleFullstack: "DESARROLLADOR FULLSTACK",
            roleUI: "DISEÑADOR UX/UI",
            description:
              "Con experiencia en diversas tecnologías tanto en el frontend como en el backend, incluyendo MySQL y MongoDB. Actualmente, estoy trabajando en un proyecto personal utilizando Node.js, React.js y MySQL, lo que me ha brindado experiencia adicional y habilidades prácticas en el desarrollo de aplicaciones web.",
            socialMedia: "Redes sociales",
          },
          aboutMe: {
            title: "ACERCA DE MÍ",
            firstDescription:
              "¡Saludos! Soy Ayoub, apasionado del Desarrollo Web y titulado en un FP Grado Superior. Aporto habilidades sólidas en lenguajes de programación tanto en el frontend como en el backend, así como en el manejo de bases de datos SQL y NoSQL. Mi fascinación por el diseño web me ha llevado a explorar el fascinante universo del UX/UI, donde he perfeccionado el uso de herramientas especializadas.",
            secondDescription:
              "Actualmente, me encuentro inmerso en un proyecto apasionante que se originó como parte de mi trayectoria académica y sigue evolucionando. Mis habilidades lingüísticas multiculturales me brindan la capacidad de comunicarme efectivamente, y tengo un ardiente deseo de aprender de los demás. Estoy entusiasmado por las infinitas posibilidades que la tecnología nos ofrece y siempre estoy dispuesto a colaborar y crecer en esta industria dinámica.",
            thirdDescription:
              "Te invito a explorar mi portafolio para descubrir más sobre mis logros y proyectos. ¡Estoy listo para nevas oportunidades y desafíos en este emocionante campo!",

            skills: "Habilidades: ",
            experienceTitle: "Experiencia",
            experience0: {
              title: "Desarrollador web",
              company: "Onizea / ",
              date: "Enero 2024 - Presente.",
              country: "España / ",
              description:
                "Power Platform - SQL - JavaScript - HTML/CSS - React - Node.js - PHP - Scrum.",
            },
            experience1: {
              title: "Programador web",
              company: "Sopra steria / ",
              date: "octubre 2023 - diciembre 2023",
              country: "España / ",
              description:
                "Spring boot - SQL - JavaScript - JSP - Java - Git - Trabajo en equipo.",
            },
            experience2: {
              title: "Programador web",
              company: "ESSISTEMAS / ",
              date: "marzo 2023 - septiembre 2023",
              country: "España / ",
              description:
                "Desarrollo de aplicaciones web con SASS, Adobe Illustrator, JavaScript, HTML/SASS, Git y UX/UI.",
            },
            experience3: {
              title: "Personal de servicio",
              company: "Illuminati Bar / ",
              date: "agosto 2020 - diciembre 2022",
              country: "España / ",
              description:
                "Atención al cliente, comunicación, memoria y atención, trabajo en equipo, organización y flexibilidad.",
            },
            experience4: {
              title: "Responsable de logistica",
              company: "Consilium sefty group / ",
              date: "febrero 2016 - junio 2018",
              country: "Marruecos / ",
              description:
                "Gestión de inventarios, planificación estratégica, coordinación de transporte, gestión de almacenes, comunicación, toma de decisiones, resolución de problemas, adaptabilidad y liderazgo.",
            },
            experience5: {
              title: "Teleoperador telefonista",
              company: "MD Call Centre / ",
              date: "octubre 2015 - enero 2016",
              country: "Marruecos / ",
              description:
                "Escucha activa, empatía, comunicación efectiva, paciencia, resistencia al estrés y escucha activa.",
            },

            educationTitle: "Educación",
            education1: {
              title: "Desarrollo de aplicaciones web",
              description:
                "Nivel: Grado Superior / Técnico Superior, Instituto: La Salle Gracia Barcelona, Área: Informática y Comunicaciones, Finalizado en Junio de 2023",
            },
            education2: {
              title: "Administración de Sistemas Informáticos en Red",
              description:
                "Nivel: Grado Superior / Técnico Superior, Instituto: ISTA Ourzazate, Área: Informática y Comunicaciones, Finalizado en Julio de 2015",
            },
            education3: {
              title: "Bachillerato en Química física",
              description:
                " Nivel: Bachillerato, Instituto: Lycée Mohamed Six Ouarzazate, Finalizado en Julio de 2013",
            },
            skillsTitle: "Habilidades",

            langTitle: "Idiomas",
            lang1: {
              title: "Amazigh",
            },
            lang2: {
              title: "Árabe",
            },
            lang3: {
              title: "Español",
            },
            lang4: {
              title: "Inglés",
            },
            lang5: {
              title: "Francés",
            },
            lang6: {
              title: "Catalán",
            },
          },
          levelLang: {
            basic: "Básico",
            intermediate: "Intermedio",
            advanced: "Avanzado",
            fluent: "Fluido",
            native: "Nativo",
          },

          projects: {
            title: "PROYECTOS RECIENTES",
            subtitle: "PROYECTOS [EN PROCESO...]",
            description:
              "A continuación, se muestran algunos de mis proyectos recientes. ¡Echa un vistazo!",
            project1: {
              title: "Scanurorder",
              description:
                "es una aplicación web diseñada para simplificar la gestión de restaurantes. Permite a los camareros tomar pedidos de manera eficiente y ofrece a los clientes la posibilidad de pedir desde sus mesas si el camarero está ocupado. Además, facilita la organización de reservas. Con Scanurorder, los restaurantes pueden mejorar la experiencia del cliente y optimizar sus operaciones. Esta aplicación sigue trabajando en añadir más funciones para brindar un servicio completo a la industria de la hostelería. Información de prueba: correo electrónico ",
              password: " y contraseña ",
            },
            project2: {
              title: "La página de inicio de Inmovalley ",
              describe: "es una réplica exacta del sitio web ",
              description:
                ", un destacado referente en el mundo de las viviendas. Ofrece una experiencia similar en la búsqueda y compra de propiedades, brindando a los usuarios acceso a una amplia gama de opciones inmobiliarias y recursos confiables en el sector. He elegido esta página de inicio porque tiene muchos efectos que me permiten ampliar mis conocimientos utilizando Sass, contribuyendo así a mi crecimiento en el desarrollo front-end.",
            },
            project3: {
              description:
                "es una aplicación web envolvente que te invita a explorar y descubrir una diversidad de películas. Con una interfaz intuitiva, conecta con The Movie Database (TMDb) para proporcionar información detallada sobre películas populares, ofreciendo acceso a sinopsis, fechas de lanzamiento, calificaciones y elenco. La aplicación presenta una galería dinámica que facilita la navegación con controles de paginación, y una función de búsqueda para encontrar películas específicas. Desarrollada con React y la API de TMDb, Movie Info garantiza una experiencia fluida mediante React Router. Sumérgete en el cine con Movie Info y disfruta de una emocionante forma de descubrir y conocer a fondo tus películas favoritas.",
            },
            project4: {
              description1:
                " una aplicación que brinda a los usuarios la opción de registrar una cuenta o iniciar sesión para probar la funcionalidad sin necesidad de registro. En caso de que no tengas una cuenta existente, puedes utilizar la siguiente información de prueba: correo electrónico ",

              password: " y contraseña ",

              description2:
                " La aplicación te presenta una lista de temporadas disponibles. Si no encuentras la temporada que buscas, tienes la capacidad de crear una nueva. A partir de ahí, puedes gestionar tus partidos, editando, creando o eliminando eventos asociados a cada temporada. También puedes mantener un historial de resultados de tus eventos. Match Event está desarrollada utilizando tecnologías como Laravel, Bootstrap, CSS y MySQL.",
            },

            project5: {
              description:
                " es una aplicación interactiva que permite a los usuarios registrarse con su correo electrónico, nombre de usuario y nombre completo mediante la API 'jsonplaceholder.typicode.com', y también realiza una excelente consumición de la API de Star Wars. Además de simular la creación de usuarios, la aplicación aprovecha la API de Star Wars para mostrar detalles fascinantes sobre los planetas de la saga. La implementación demuestra mi destreza en integrar múltiples APIs, gestionar datos de usuarios y proporcionar una experiencia completa y envolvente para el usuario, resaltando así mi habilidad técnica y creativa en el desarrollo de aplicaciones.",
            },

            project6: {
              description:
                "es una emocionante aplicación en proceso que te permite crear tu equipo favorito utilizando jugadores de la liga. Esta innovadora plataforma te brinda la oportunidad de armar un equipo de ensueño con los mejores jugadores de la liga y competir en desafiantes partidos virtuales. Prepárate para vivir una experiencia deportiva única y emocionante mientras esta aplicación sigue desarrollándose.",
            },
            project7: {
              description:
                "Desarrollé una página web receptiva utilizando React y CSS para BIO WEB, una empresa especializada en servicios de limpieza ecológicos. El sitio web cuenta con secciones que incluyen Inicio, Acerca de, Servicios y Contacto, destacando los beneficios de utilizar productos bio para la limpieza al tiempo que proporciona un fácil acceso a la información y programación de servicios.",
            },
            projectList: {
              access: "Acceder a ",
            },
          },

          contact: {
            title: "CONTACTO",
            subtitle: "¿HABLAMOS?",
            description:
              "Si tienes alguna pregunta o quieres saber más sobre mí, no dudes en contactarme. ¡Estaré encantado de responderte!",
            name: "Nombre",
            email: "Correo electrónico",
            subject: "Asunto",
            message: "Mensaje",
            send: "Enviar",
            messageSent: "Mensaje enviado con éxito.",
            errorMsg:
              "Ha ocurrido un error. Puedes intentarlo más tarde o contactarme a través de mi correo electrónico.",
            emptyfields: "Por favor rellena todos los campos.",
            invalidEmail:
              "Por favor, introduce una dirección de correo electrónico válida.",
          },

          footer: {
            title: "© 2024 Ayoub Bouazi",
            subtitle: "Todos los derechos reservados.",
            contactInfo: "contacto",
            phone: "Teléfono",
            email: "Correo electrónico",
          },
        },
      },
      en: {
        translation: {
          header: {
            language: "En",
            es: "Spanish",
            en: "English",
            cat: "Catalan",
            ar: "Arabic",
            fr: "French",
            home: "Home",
            aboutMe: "About me",
            projects: "Projects",
            contact: "Contact",
          },
          home: {
            title: "AYOUB BOUAZI",
            subtitle: "I'M: ",
            roleFullstack: "FULLSTACK DEVELOPER",
            roleUI: "UX/UI DESIGNER",
            description:
              "With experience in various technologies both frontend and backend, including MySQL and MongoDB. Currently, I am working on a personal project using Node.js, React.js and MySQL, which has given me additional experience and practical skills in web application development.",
            socialMedia: "Social media",
          },
          aboutMe: {
            title: "ABOUT ME",
            firstDescription:
              "Greetings! I am Ayoub, passionate about Web Development and graduated in a Higher Degree FP. I bring strong skills in programming languages both frontend and backend, as well as in the management of SQL and NoSQL databases. My fascination with web design has led me to explore the fascinating universe of UX/UI, where I have perfected the use of specialized tools.",
            secondDescription:
              "Currently, I am immersed in an exciting project that originated as part of my academic career and continues to evolve. My multicultural language skills give me the ability to communicate effectively, and I have a burning desire to learn from others. I am excited about the endless possibilities that technology offers us and I am always willing to collaborate and grow in this dynamic industry.",
            thirdDescription:
              "I invite you to explore my portfolio to discover more about my achievements and projects. I am ready for new opportunities and challenges in this exciting field!",
            skills: "Skills: ",
            experienceTitle: "Experience",
            experience0: {
              title: "Web developer",
              company: "Onizea / ",
              date: "January 2024 - Present.",
              country: "Spain / ",
              description:
                "Power Platform - SQL - JavaScript - HTML/CSS - React - Node.js - PHP - Scrum.",
            },
            experience1: {
              title: "Web developer",
              company: "Sopra steria / ",
              date: "October 2023 - December 2023",
              country: "Spain / ",
              description:
                "Spring boot - SQL - JavaScript - JSP - Java - Git - Teamwork.",
            },
            experience2: {
              title: "Web developer",
              company: "ESSISTEMAS / ",
              date: "March 2023 - September 2023",
              country: "Spain / ",
              description:
                "Web application development with SASS, Adobe Illustrator, JavaScript, HTML/SASS, Git and UX/UI.",
            },
            experience3: {
              title: "Service staff",
              company: "Illuminati Bar / ",
              date: "August 2020 - December 2022",
              country: "Spain / ",
              description:
                "Customer service, communication, memory and attention, teamwork, organization and flexibility.",
            },
            experience4: {
              title: "Logistics manager",
              company: "Consilium sefty group / ",
              date: "February 2016 - June 2018",
              country: "Morocco / ",
              description:
                "Inventory management, strategic planning, transportation coordination, warehouse management, communication, decision making, problem solving, adaptability and leadership.",
            },
            experience5: {
              title: "Telephone operator",
              company: "MD Call Centre / ",
              date: "October 2015 - January 2016",
              country: "Morocco / ",
              description:
                "Active listening, empathy, effective communication, patience, stress resistance and active listening.",
            },
            educationTitle: "Education",
            education1: {
              title: "Web application development",
              description:
                "Level: Higher Degree / Higher Technician, Institute: La Salle Gracia Barcelona, Area: Computer Science and Communications, Completed in June 2023",
            },
            education2: {
              title: "Administration of Computer Systems in Network",
              description:
                "Level: Higher Degree / Higher Technician, Institute: ISTA Ourzazate, Area: Computer Science and Communications, Completed in July 2015",
            },
            education3: {
              title: "Baccalaureate in Physical Chemistry",
              description:
                "Level: Baccalaureate, Institute: Lycée Mohamed Six Ouarzazate, Completed in July 2013",
            },
            skillsTitle: "Skills",
            langTitle: "Languages",
            lang1: {
              title: "Amazigh",
            },
            lang2: {
              title: "Arabic",
            },
            lang3: {
              title: "Spanish",
            },
            lang4: {
              title: "English",
            },
            lang5: {
              title: "French",
            },
            lang6: {
              title: "Catalan",
            },
          },
          levelLang: {
            basic: "Basic",
            intermediate: "Intermediate",
            advanced: "Advanced",
            fluent: "Fluent",
            native: "Native",
          },

          projects: {
            title: "RECENT PROJECTS",
            subtitle: "PROJECTS [IN PROGRESS...]",
            description: "Here are some of my recent projects. Take a look!",
            project1: {
              title: "Scanurorder",
              description:
                "is a web application designed to simplify restaurant management. It allows waiters to take orders efficiently and offers customers the possibility to order from their tables if the waiter is busy. It also facilitates the organization of reservations. With Scanurorder, restaurants can improve the customer experience and optimize their operations. This application is still working on adding more features to provide a complete service to the hospitality industry. Test information: email ",
              password: " and password ",
            },
            project2: {
              title: "The Inmovalley homepage ",
              describe: "is an exact replica of the website ",
              description:
                ", a leading reference in the world of housing. It offers a similar experience in searching and buying properties, giving users access to a wide range of real estate options and reliable resources in the sector. I have chosen this homepage because it has many effects that allow me to expand my knowledge using Sass, thus contributing to my growth in front-end development.",
            },
            project3: {
              description:
                "is an immersive web application that invites you to explore and discover a diversity of movies. With an intuitive interface, it connects with The Movie Database (TMDb) to provide detailed information about popular movies, offering access to synopses, release dates, ratings and cast. The application features a dynamic gallery that facilitates navigation with pagination controls, and a search function to find specific movies. Developed with React and the TMDb API, Movie Info guarantees a smooth experience through React Router. Immerse yourself in the cinema with Movie Info and enjoy an exciting way to discover and get to know your favorite movies in depth.",
            },
            project4: {
              description1:
                "an application that gives users the option to register an account or log in to test the functionality without registration. In case you do not have an existing account, you can use the following test information: email ",

              password: " and password ",

              description2:
                " The application presents you with a list of available seasons. If you don't find the season you're looking for, you have the ability to create a new one. From there, you can manage your matches, editing, creating or deleting events associated with each season. You can also keep a history of results from your events. Match Event is developed using technologies such as Laravel, Bootstrap, CSS and MySQL.",
            },

            project5: {
              description:
                "is an interactive application that allows users to register with their email, username and full name through the API 'jsonplaceholder.typicode.com', and also makes an excellent consumption of the Star Wars API. In addition to simulating the creation of users, the application takes advantage of the Star Wars API to show fascinating details about the saga's planets. The implementation demonstrates my skill in integrating multiple APIs, managing user data and providing a complete and immersive experience for the user, thus highlighting my technical and creative ability in application development.",
            },
            project6: {
              description:
                "is an exciting application in progress that allows you to create your favorite team using league players. This innovative platform gives you the opportunity to put together a dream team with the best players in the league and compete in challenging virtual matches. Get ready to live a unique and exciting sports experience as this application continues to develop.",
            },
            project7: {
              description:
                "Developed a responsive website using React and CSS for BIO WEB, a company specializing in eco-friendly cleaning services. The website features sections including Home, About, Services, and Contact, highlighting the benefits of using bio products for cleaning while providing easy access to information and scheduling services.",
            },
            projectList: {
              access: "Access to ",
            },
          },

          contact: {
            title: "CONTACT",
            subtitle: "LET'S TALK?",
            description:
              "If you have any questions or want to know more about me, do not hesitate to contact me. I will be happy to answer you!",
            name: "Name",
            email: "Email",
            subject: "Subject",
            message: "Message",
            send: "Send",
            messageSent: "Message sent successfully.",
            errorMsg:
              "An error has occurred. You can try again later or contact me via my email.",
            emptyfields: "Please fill in all fields.",
            invalidEmail: "Please enter a valid email address.",
          },

          footer: {
            title: "© 2024 Ayoub Bouazi",
            subtitle: "All rights reserved.",
            contactInfo: "Contact",
            phone: "Phone",
            email: "Email",
          },
        },
      },
      cat: {
        translation: {
          header: {
            language: "Cat",
            es: "Espanyol",
            en: "Anglès",
            cat: "Català",
            ar: "Àrab",
            fr: "Francès",
            home: "Inici",
            aboutMe: "Sobre mi",
            projects: "Projectes",
            contact: "Contacte",
          },
          home: {
            title: "AYOUB BOUAZI",
            subtitle: "SÓC: ",
            roleFullstack: "DESENVOLUPADOR FULLSTACK",
            roleUI: "DISSENYADOR UX/UI",
            description:
              "Amb experiència en diverses tecnologies tant al frontend com al backend, incloent MySQL i MongoDB. Actualment, estic treballant en un projecte personal utilitzant Node.js, React.js i MySQL, el que m'ha brindat experiència addicional i habilitats pràctiques en el desenvolupament d'aplicacions web.",
            socialMedia: "Xarxes socials",
          },
          aboutMe: {
            title: "SOBRE MI",
            firstDescription:
              "Salutacions! Sóc l'Ayoub, apassionat del Desenvolupament Web i titulat en un FP Grau Superior. Aporto habilitats sòlides en llenguatges de programació tant al frontend com al backend, així com en el maneig de bases de dades SQL i NoSQL. La meva fascinació pel disseny web m'ha portat a explorar el fascinant univers del UX/UI, on he perfeccionat l'ús d'eines especialitzades.",
            secondDescription:
              "Actualment, em trobo immers en un projecte apassionant que va originar-se com a part de la meva trajectòria acadèmica i continua evolucionant. Les meves habilitats lingüístiques multiculturals em donen la capacitat de comunicar-me efectivament, i tinc un ardorós desig d'aprendre dels altres. Estic entusiasmat per les infinites possibilitats que la tecnologia ens ofereix i sempre estic disposat a col·laborar i créixer en aquesta indústria dinàmica.",
            thirdDescription:
              "Et convido a explorar el meu portafoli per descobrir més sobre els meus assoliments i projectes. Estic llest per a noves oportunitats i reptes en aquest emocionant camp!",
            skills: "Habilitats: ",
            experienceTitle: "Experiència",
            experience0: {
              title: "Desenvolupador web",
              company: "Onizea / ",
              date: "Gener 2024 - Present.",
              country: "Espanya / ",
              description:
                "Power Platform - SQL - JavaScript - HTML/CSS - React - Node.js - PHP - Scrum.",
            },
            experience1: {
              title: "Programador web",
              company: "Sopra steria / ",
              date: "octubre 2023 - diciembre 2023",
              country: "Espanya / ",
              description:
                "Spring boot - SQL - JavaScript - JSP - Java - Git - Treball en equip.",
            },
            experience2: {
              title: "Programador web",
              company: "ESSISTEMAS / ",
              date: "marzo 2023 - septiembre 2023",
              country: "Espanya / ",
              description:
                "Desenvolupament d'aplicacions web amb SASS, Adobe Illustrator, JavaScript, HTML/SASS, Git i UX/UI.",
            },
            experience3: {
              title: "Personal de servei",
              company: "Illuminati Bar / ",
              date: "agosto 2020 - diciembre 2022",
              country: "Espanya / ",
              description:
                "Atenció al client, comunicació, memòria i atenció, treball en equip, organització i flexibilitat.",
            },
            experience4: {
              title: "Responsable de logística",
              company: "Consilium sefty group / ",
              date: "febrero 2016 - junio 2018",
              country: "Marroc / ",
              description:
                "Gestió d'inventaris, planificació estratègica, coordinació de transport, gestió d'almacens, comunicació, presa de decisions, resolució de problemes, adaptabilitat i lideratge.",
            },
            experience5: {
              title: "Teleoperador telefonista",
              company: "MD Call Centre / ",
              date: "octubre 2015 - enero 2016",
              country: "Marroc / ",
              description:
                "Escolta activa, empatia, comunicació efectiva, paciència, resistència a l'estrès i escolta activa.",
            },
            educationTitle: "Educació",
            education1: {
              title: "Desenvolupament d'aplicacions web",
              description:
                "Nivell: Grau Superior / Tècnic Superior, Institut: La Salle Gràcia Barcelona, Àrea: Informàtica i Comunicacions, Finalitzat en Juny de 2023",
            },
            education2: {
              title: "Administració de Sistemes Informàtics en Xarxa",
              description:
                "Nivell: Grau Superior / Tècnic Superior, Institut: ISTA Ourzazate, Àrea: Informàtica i Comunicacions, Finalitzat en Juliol de 2015",
            },
            education3: {
              title: "Batxillerat en Química física",
              description:
                " Nivell: Batxillerat, Institut: Lycée Mohamed Six Ouarzazate, Finalitzat en Juliol de 2013",
            },
            skillsTitle: "Habilitats",
            langTitle: "Idiomes",
            lang1: {
              title: "Amazic",
            },
            lang2: {
              title: "Àrab",
            },
            lang3: {
              title: "Espanyol",
            },
            lang4: {
              title: "Anglès",
            },
            lang5: {
              title: "Francès",
            },
            lang6: {
              title: "Català",
            },
          },
          levelLang: {
            basic: "Bàsic",
            intermediate: "Intermedi",
            advanced: "Avançat",
            fluent: "Fluent",
            native: "Natiu",
          },

          projects: {
            title: "PROJECTES RECENTS",
            subtitle: "PROJECTES [EN PROCÉS...]",
            description:
              "A continuació, es mostren alguns dels meus projectes recents. Fes un cop d'ull!",
            project1: {
              title: "Scanurorder",
              description:
                "és una aplicació web dissenyada per simplificar la gestió de restaurants. Permet als cambrers prendre comandes de manera eficient i ofereix als clients la possibilitat de demanar des de les seves taules si el cambrer està ocupat. A més, facilita l'organització de reserves. Amb Scanurorder, els restaurants poden millorar l'experiència del client i optimitzar les seves operacions. Aquesta aplicació segueix treballant en afegir més funcions per proporcionar un servei complet a la indústria de l'hostaleria. Informació de prova: correu electrònic ",
              password: " i contrasenya ",
            },
            project2: {
              title: "La pàgina d'inici d'Inmovalley ",
              describe: "és una rèplica exacta del lloc web ",
              description:
                ", un destacat referent en el món de les habitatges. Ofereix una experiència similar en la cerca i compra de propietats, donant als usuaris accés a una àmplia gamma d'opcions immobiliàries i recursos fiables en el sector. He triat aquesta pàgina d'inici perquè té molts efectes que em permeten ampliar els meus coneixements utilitzant Sass, contribuint així al meu creixement en el desenvolupament front-end.",
            },
            project3: {
              description:
                "és una aplicació web immersiva que t'invita a explorar i descobrir una diversitat de pel·lícules. Amb una interfície intuïtiva, connecta amb The Movie Database (TMDb) per proporcionar informació detallada sobre pel·lícules populars, oferint accés a sinopsi, dates de llançament, qualificacions i repartiment. L'aplicació presenta una galeria dinàmica que facilita la navegació amb controls de paginació, i una funció de cerca per trobar pel·lícules específiques. Desenvolupada amb React i l'API de TMDb, Movie Info garanteix una experiència fluida mitjançant React Router. Submergeix-te en el cinema amb Movie Info i gaudeix d'una emocionant manera de descobrir i conèixer a fons les teves pel·lícules preferides.",
            },
            project4: {
              description1:
                "una aplicació que dóna als usuaris l'opció de registrar un compte o iniciar sessió per provar la funcionalitat sense necessitat de registre. En cas que no tinguis un compte existent, pots utilitzar la següent informació de prova: correu electrònic ",

              password: " i contrasenya ",

              description2:
                " L'aplicació et presenta una llista de temporades disponibles. Si no trobes la temporada que busques, tens la capacitat de crear una nova. A partir d'aquí, pots gestionar els teus partits, editant, creant o eliminant esdeveniments associats a cada temporada. També pots mantenir un historial de resultats dels teus esdeveniments. Match Event està desenvolupada utilitzant tecnologies com Laravel, Bootstrap, CSS i MySQL.",
            },
            project5: {
              description:
                "és una aplicació interactiva que permet als usuaris registrar-se amb el seu correu electrònic, nom d'usuari i nom complet mitjançant l'API 'jsonplaceholder.typicode.com', i també realitza una excel·lent consumició de l'API de Star Wars. A més de simular la creació d'usuaris, l'aplicació aprofita l'API de Star Wars per mostrar detalls fascinants sobre els planetes de la saga. La implementació demostra la meva destresa en integrar múltiples APIs, gestionar dades d'usuaris i proporcionar una experiència completa i immersiva per a l'usuari, ressaltant així la meva habilitat tècnica i creativa en el desenvolupament d'aplicacions.",
            },
            project6: {
              description:
                "és una emocionant aplicació en procés que et permet crear el teu equip favorit utilitzant jugadors de la lliga. Aquesta innovadora plataforma et brinda l'oportunitat de muntar un equip de somni amb els millors jugadors de la lliga i competir en desafiaments virtuals. Prepara't per viure una experiència esportiva única i emocionant mentre aquesta aplicació segueix desenvolupant-se.",
            },
            project7: {
              description:
                "Vaig desenvolupar una página web receptiva utilitzant React i CSS per a BIO WEB, una empresa especialitzada en serveis de neteja ecològics. El lloc web compta amb seccions que inclouen Inici, Sobre, Serveis i Contacte, destacant els beneficis d'utilitzar productes bio per a la neteja alhora que proporciona un fàcil accés a la informació i programació de serveis.",
            },
            projectList: {
              access: "Accedir a ",
            },
          },

          contact: {
            title: "CONTACTE",
            subtitle: "PARLEM?",
            description:
              "Si tens alguna pregunta o vols saber més sobre mi, no dubtis a contactar-me. Estaré encantat de respondre't!",
            name: "Nom",
            email: "Correu electrònic",
            subject: "Assumpte",
            message: "Missatge",
            send: "Enviar",
            messageSent: "Missatge enviat amb èxit.",
            errorMsg:
              "Ha ocorregut un error. Pots intentar-ho més tard o contactar-me a través del meu correu electrònic.",
            emptyfields: "Si us plau omple tots els camps.",
            invalidEmail:
              "Si us plau, introdueix una adreça de correu electrònic vàlida.",
          },

          footer: {
            title: "© 2024 Ayoub Bouazi",
            subtitle: "Tots els drets reservats.",
            contactInfo: "contacte",
            phone: "Telèfon",
            email: "Correu electrònic",
          },
        },
      },
      ar: {
        translation: {
          header: {
            language: "Ar",
            es: "إسباني",
            en: "إنجليزي",
            cat: "كاتالوني",
            ar: "عربي",
            fr: "فرنسي",
            home: "الصفحة الرئيسية",
            aboutMe: "معلومات عني",
            projects: "مشاريع",
            contact: "اتصل",
          },
          home: {
            title: "أيوب بوعزي",
            subtitle: "أنا: ",
            roleFullstack: "مطور ويب",
            roleUI: "مصمم واجهة المستخدم",
            description:
              "لدي خبرة في مجموعة متنوعة من التقنيات في الواجهة الأمامية والخلفية ، بما في ذلك MySQL و MongoDB. حاليًا ، أعمل على مشروع شخصي باستخدام Node.js و React.js و MySQL ، مما منحني خبرة إضافية ومهارات عملية في تطوير تطبيقات الويب.",
            socialMedia: "وسائل التواصل",
          },
          aboutMe: {
            title: "معلومات عني",
            firstDescription:
              "تحياتي! أنا أيوب ، مولع بتطوير الويب وحاصل على درجة عالية في FP. أحضر مهارات قوية في لغات البرمجة في الواجهة الأمامية والخلفية ، وكذلك في إدارة قواعد البيانات SQL و NoSQL. لقد أدى إعجابي بتصميم الويب إلى استكشاف كونه الكون الساحر ل UX / UI ، حيث قمت بتحسين استخدام الأدوات المتخصصة.",
            secondDescription:
              "حاليًا ، أنا غارق في مشروع مثير نشأ كجزء من مسيرتي الأكاديمية ولا يزال يتطور. تمنحني مهاراتي اللغوية المتعددة الثقافات القدرة على التواصل بشكل فعال ، ولدي رغبة حارقة في التعلم من الآخرين. أنا متحمس للإمكانات اللانهائية التي توفرها لنا التكنولوجيا وأنا على استعداد دائم للتعاون والنمو في هذه الصناعة الديناميكية.",
            thirdDescription:
              "أدعوك لاستكشاف محفظتي لاكتشاف المزيد عن إنجازاتي ومشاريعي. أنا مستعد لفرص وتحديات جديدة في هذا المجال المثير!",
            skills: "مهارات: ",
            experienceTitle: "تجربة",
            experience0: {
              title: "مطور ويب",
              company: "Onizea / ",
              date: "يناير 2024 - الآن.",
              country: "إسبانيا / ",
              description:
                "Power Platform - SQL - JavaScript - HTML/CSS - React - Node.js - PHP - Scrum.",
            },
            experience1: {
              title: "مطور ويب",
              company: "Sopra steria / ",
              date: "أكتوبر 2023 - ديسمبر 2023",
              country: "إسبانيا / ",
              description:
                "Spring boot - SQL - JavaScript - JSP - Java - Git - العمل الجماعي.",
            },
            experience2: {
              title: "مطور ويب",
              company: "ESSISTEMAS / ",
              date: "مارس 2023 - سبتمبر 2023",
              country: "إسبانيا / ",
              description:
                "تطوير تطبيقات الويب مع SASS و Adobe Illustrator و JavaScript و HTML / SASS و Git و UX / UI.",
            },
            experience3: {
              title: "موظف خدمة",
              company: "Illuminati Bar / ",
              date: "أغسطس 2020 - ديسمبر 2022",
              country: "إسبانيا / ",
              description:
                "خدمة العملاء والاتصالات والذاكرة والاهتمام والعمل الجماعي والتنظيم والمرونة.",
            },
            experience4: {
              title: "مدير اللوجستيات",
              company: "Consilium sefty group / ",
              date: "فبراير 2016 - يونيو 2018",
              country: "المغرب / ",
              description:
                "إدارة المخزون والتخطيط الاستراتيجي وتنسيق النقل وإدارة المستودعات والاتصالات واتخاذ القرارات وحل المشكلات والتكيف والقيادة.",
            },
            experience5: {
              title: "مشغل الهاتف",
              company: "MD Call Centre / ",
              date: "أكتوبر 2015 - يناير 2016",
              country: "المغرب / ",
              description:
                "الاستماع النشط والتعاطف والاتصال الفعال والصبر ومقاومة الإجهاد والاستماع النشط.",
            },
            educationTitle: "تعليم",
            education1: {
              title: "تطوير تطبيقات الويب",
              description:
                "المستوى: درجة عالية / فني عالي ، المعهد: لا سال غراسيا برشلونة، المجال: علوم الكمبيوتر والاتصالات ، انتهى في يونيو 2023",
            },
            education2: {
              title: "إدارة أنظمة الكمبيوتر في الشبكة",
              description:
                "المستوى: درجة عالية / فني عالي ، المعهد: ISTA Ourzazate ، المجال: علوم الكمبيوتر والاتصالات ، انتهى في يوليو 2015",
            },
            education3: {
              title: "البكالوريا في الكيمياء الفيزيائية",
              description:
                "المستوى: البكالوريا ، المعهد: Lycée Mohamed Six Ouarzazate ، انتهى في يوليو 2013",
            },
            skillsTitle: "مهارات",
            langTitle: "اللغات",
            lang1: {
              title: "أمازيغي",
            },
            lang2: {
              title: "عربي",
            },
            lang3: {
              title: "إسباني",
            },
            lang4: {
              title: "إنجليزي",
            },
            lang5: {
              title: "فرنسي",
            },
            lang6: {
              title: "كاتالوني",
            },
          },
          levelLang: {
            basic: "أساسي",
            intermediate: "متوسط",
            advanced: "متقدم",
            fluent: "طلاقة",
            native: "أصلي",
          },

          projects: {
            title: "المشاريع الأخيرة",
            subtitle: "المشاريع [قيد التقدم...]",
            description: "فيما يلي بعض المشاريع الأخيرة. تفضل بالإطلاع!",
            project1: {
              title: "Scanurorder",
              description:
                "هو تطبيق ويب مصمم لتبسيط إدارة المطاعم. يتيح للنادلين أخذ الطلبات بكفاءة ويوفر للعملاء إمكانية الطلب من طاولاتهم إذا كان النادل مشغولًا. كما أنه يسهل تنظيم الحجوزات. مع Scanurorder ، يمكن للمطاعم تحسين تجربة العملاء وتحسين عملياتها. لا يزال هذا التطبيق يعمل على إضافة المزيد من الميزات لتوفير خدمة كاملة لصناعة الضيافة. معلومات الاختبار: البريد الإلكتروني ",
              password: " وكلمة المرور ",
            },
            project2: {
              title: "الصفحة الرئيسية لـ Inmovalley ",
              describe: "هي نسخة طبق الأصل من موقع ",
              description:
                ", مرجع رائد في عالم الإسكان. يوفر تجربة مماثلة في البحث وشراء العقارات ، مما يمنح المستخدمين الوصول إلى مجموعة واسعة من خيارات العقارات والموارد الموثوقة في القطاع. لقد اخترت هذه الصفحة الرئيسية لأنها بها العديد من التأثيرات التي تتيح لي توسيع معرفتي باستخدام Sass ، مما يساهم في نموي في تطوير الواجهة الأمامية.",
            },
            project3: {
              description:
                "هو تطبيق ويب غامر يدعوك لاستكشاف واكتشاف مجموعة متنوعة من الأفلام. مع واجهة مستخدم بديهية ، يتصل بقاعدة بيانات أفلام The Movie Database (TMDb) لتوفير معلومات مفصلة حول الأفلام الشائعة ، مما يوفر الوصول إلى الخلاصات وتواريخ الإصدار والتصنيفات والطاقم. يتميز التطبيق بمعرض ديناميكي يسهل التنقل مع عناصر تحكم الترقيم ، ووظيفة البحث للعثور على أفلام محددة. تم تطوير Movie Info باستخدام React و TMDb API ، ويضمن تجربة سلسة من خلال React Router. اغمر نفسك في السينما مع Movie Info واستمتع بطريقة مثيرة للاهتمام لاكتشاف ومعرفة أفلامك المفضلة بعمق.",
            },
            project4: {
              description1:
                "تطبيق يتيح للمستخدمين خيار تسجيل حساب أو تسجيل الدخول لاختبار الوظيفة دون التسجيل. في حالة عدم وجود حساب موجود ، يمكنك استخدام معلومات الاختبار التالية: البريد الإلكتروني ",

              password: " وكلمة المرور ",

              description2:
                " يقدم لك التطبيق قائمة بالمواسم المتاحة. إذا لم تجد الموسم الذي تبحث عنه ، فلديك القدرة على إنشاء موسم جديد. من هناك ، يمكنك إدارة مبارياتك ، وتحريرها ، وإنشاء أو حذف الأحداث المرتبطة بكل موسم. يمكنك أيضًا الاحتفاظ بسجل نتائج أحداثك. تم تطوير Match Event باستخدام تقنيات مثل Laravel و Bootstrap و CSS و MySQL.",
            },
            project5: {
              description:
                "هو تطبيق تفاعلي يتيح للمستخدمين التسجيل باستخدام بريدك الإلكتروني واسم المستخدم والاسم الكامل من خلال API 'jsonplaceholder.typicode.com' ، ويستفيد أيضًا من API Star Wars. بالإضافة إلى محاكاة إنشاء المستخدمين ، يستفيد التطبيق من API Star Wars لعرض تفاصيل رائعة حول كواكب الملحمة. توضح التنفيذ مهارتي في دمج عدة APIs وإدارة بيانات المستخدم وتوفير تجربة كاملة وغامرة للمستخدم ، مما يبرز قدرتي التقنية والإبداعية في تطوير التطبيقات.",
            },
            project6: {
              description:
                "هو تطبيق مثير للإثارة قيد التطوير يتيح لك إنشاء فريقك المفضل باستخدام لاعبي الدوري. توفر هذه المنصة المبتكرة لك الفرصة لتجميع فريق أحلام مع أفضل اللاعبين في الدوري والمنافسة في تحديات افتراضية صعبة. استعد لتعيش تجربة رياضية فريدة ومثيرة مع استمرار تطوير هذا التطبيق.",
            },
            project7: {
              description:
                "طورت موقع ويب متجاوب باستخدام React و CSS لـ BIO WEB ، شركة متخصصة في خدمات التنظيف البيئية. يتضمن الموقع أقسامًا تشمل الصفحة الرئيسية وحول والخدمات والاتصال ، مبرزًا فوائد استخدام منتجات البيو للتنظيف مع توفير وصول سهل إلى معلومات وجدولة الخدمات.",
            },
            projectList: {
              access: "الوصول إلى ",
            },
          },

          contact: {
            title: "اتصل",
            subtitle: "هل نتحدث؟",
            description:
              "إذا كان لديك أي أسئلة أو ترغب في معرفة المزيد عني ، فلا تتردد في الاتصال بي. سأكون سعيدًا للرد عليك!",
            name: "اسم",
            email: "البريد الإلكتروني",
            subject: "موضوع",
            message: "رسالة",
            send: "إرسال",
            messageSent: "تم إرسال الرسالة بنجاح.",
            errorMsg:
              "حدث خطأ. يمكنك المحاولة مرة أخرى في وقت لاحق أو الاتصال بي عبر البريد الإلكتروني الخاص بي.",
            emptyfields: "يرجى ملء جميع الحقول.",
            invalidEmail: "الرجاء إدخال عنوان بريد إلكتروني صالح.",
          },

          footer: {
            title: "© 2024 أيوب بوعزي",
            subtitle: "كل الحقوق محفوظة.",
            contactInfo: "الاتصال",
            phone: "هاتف",
            email: "البريد الإلكتروني",
          },
        },
      },
      fr: {
        translation: {
          header: {
            language: "Fr",
            es: "Espagnol",
            en: "Anglais",
            cat: "Catalan",
            ar: "Arabe",
            fr: "Français",
            home: "Accueil",
            aboutMe: "À propos de moi",
            projects: "Projets",
            contact: "Contact",
          },
          home: {
            title: "AYOUB BOUAZI",
            subtitle: "JE SUIS: ",
            roleFullstack: "DÉVELOPPEUR FULLSTACK",
            roleUI: "DESIGNER UX/UI",
            description:
              "Avec une expérience dans diverses technologies à la fois frontend et backend, y compris MySQL et MongoDB. Actuellement, je travaille sur un projet personnel utilisant Node.js, React.js et MySQL, ce qui m'a apporté une expérience supplémentaire et des compétences pratiques dans le développement d'applications Web.",
            socialMedia: "Médias sociaux",
          },
          aboutMe: {
            title: "À PROPOS DE MOI",
            firstDescription:
              "Salutations! Je suis Ayoub, passionné par le développement Web et diplômé d'un FP de haut niveau. Je possède de solides compétences en langages de programmation à la fois frontend et backend, ainsi que dans la gestion des bases de données SQL et NoSQL. Mon fascination pour la conception Web m'a amené à explorer l'univers fascinant de l'UX/UI, où j'ai perfectionné l'utilisation d'outils spécialisés.",
            secondDescription:
              "Actuellement, je suis plongé dans un projet passionnant qui a vu le jour dans le cadre de ma carrière universitaire et continue d'évoluer. Mes compétences linguistiques multiculturelles me donnent la capacité de communiquer efficacement, et j'ai un désir ardent d'apprendre des autres. Je suis enthousiasmé par les possibilités infinies que la technologie nous offre et je suis toujours prêt à collaborer et à grandir dans cette industrie dynamique.",
            thirdDescription:
              "Je vous invite à explorer mon portfolio pour en savoir plus sur mes réalisations et projets. Je suis prêt pour de nouvelles opportunités et défis dans ce domaine passionnant!",
            skills: "Compétences: ",
            experienceTitle: "Expérience",
            experience0: {
              title: "Développeur Web",
              company: "Onizea / ",
              date: "janvier 2024 - maintenant",
              country: "Espagne / ",
              description:
                "Power Platform - SQL - JavaScript - HTML/CSS - React - Node.js - PHP - Scrum.",
            },
            experience1: {
              title: "Développeur Web",
              company: "Sopra steria / ",
              date: "octobre 2023 - décembre 2023",
              country: "Espagne / ",
              description:
                "Spring boot - SQL - JavaScript - JSP - Java - Git - Travail d'équipe.",
            },
            experience2: {
              title: "Développeur Web",
              company: "ESSISTEMAS / ",
              date: "mars 2023 - septembre 2023",
              country: "Espagne / ",
              description:
                "Développement d'applications Web avec SASS, Adobe Illustrator, JavaScript, HTML/SASS, Git et UX/UI.",
            },
            experience3: {
              title: "Personnel de service",
              company: "Illuminati Bar / ",
              date: "août 2020 - décembre 2022",
              country: "Espagne / ",
              description:
                "Service client, communication, mémoire et attention, travail d'équipe, organisation et flexibilité.",
            },
            experience4: {
              title: "Responsable logistique",
              company: "Consilium sefty group / ",
              date: "février 2016 - juin 2018",
              country: "Maroc / ",
              description:
                "Gestion des stocks, planification stratégique, coordination des transports, gestion des entrepôts, communication, prise de décision, résolution de problèmes, adaptabilité et leadership.",
            },
            experience5: {
              title: "Opérateur téléphonique",
              company: "MD Call Centre / ",
              date: "octobre 2015 - janvier 2016",
              country: "Maroc / ",
              description:
                "Écoute active, empathie, communication efficace, patience, résistance au stress et écoute active.",
            },
            educationTitle: "Éducation",
            education1: {
              title: "Développement d'applications Web",
              description:
                "Niveau: Diplôme supérieur / Technicien supérieur, Institut: La Salle Gracia Barcelone, Domaine: Informatique et communications, Terminé en juin 2023",
            },
            education2: {
              title: "Administration des systèmes informatiques en réseau",
              description:
                "Niveau: Diplôme supérieur / Technicien supérieur, Institut: ISTA Ourzazate, Domaine: Informatique et communications, Terminé en juillet 2015",
            },
            education3: {
              title: "Baccalauréat en chimie physique",
              description:
                " Niveau: Baccalauréat, Institut: Lycée Mohamed Six Ouarzazate, Terminé en juillet 2013",
            },
            skillsTitle: "Compétences",
            langTitle: "Langues",
            lang1: {
              title: "Amazigh",
            },
            lang2: {
              title: "Arabe",
            },
            lang3: {
              title: "Espagnol",
            },
            lang4: {
              title: "Anglais",
            },
            lang5: {
              title: "Français",
            },
            lang6: {
              title: "Catalan",
            },
          },
          levelLang: {
            basic: "De base",
            intermediate: "Intermédiaire",
            advanced: "Avancé",
            fluent: "Courant",
            native: "Natif",
          },

          projects: {
            title: "PROJETS RÉCENTS",
            subtitle: "PROJETS [EN COURS...]",
            description:
              "Voici quelques-uns de mes projets récents. Jetez un œil!",
            project1: {
              title: "Scanurorder",
              description:
                "est une application Web conçue pour simplifier la gestion des restaurants. Il permet aux serveurs de prendre des commandes efficacement et offre aux clients la possibilité de commander depuis leurs tables si le serveur est occupé. De plus, il facilite l'organisation des réservations. Avec Scanurorder, les restaurants peuvent améliorer l'expérience client et optimiser leurs opérations. Cette application continue de travailler à l'ajout de plus de fonctionnalités pour fournir un service complet à l'industrie de l'hôtellerie. Informations de test: e-mail ",
              password: " et mot de passe ",
            },
            project2: {
              title: "La page d'accueil d'Inmovalley ",
              describe: "est une réplique exacte du site Web ",
              description:
                ", une référence de premier plan dans le monde de l'immobilier. Il offre une expérience similaire dans la recherche et l'achat de biens immobiliers, donnant aux utilisateurs accès à une large gamme d'options immobilières et à des ressources fiables dans le secteur. J'ai choisi cette page d'accueil car elle a de nombreux effets qui me permettent d'élargir mes connaissances en utilisant Sass, contribuant ainsi à ma croissance dans le développement frontend.",
            },
            project3: {
              description:
                "est une application Web immersive qui vous invite à explorer et à découvrir une diversité de films. Avec une interface utilisateur intuitive, il se connecte à The Movie Database (TMDb) pour fournir des informations détaillées sur les films populaires, offrant un accès aux synopsis, dates de sortie, classements et casting. L'application présente une galerie dynamique qui facilite la navigation avec des contrôles de pagination, et une fonction de recherche pour trouver des films spécifiques. Développé avec React et l'API TMDb, Movie Info garantit une expérience fluide via React Router. Plongez dans le cinéma avec Movie Info et profitez d'une façon passionnante de découvrir et de connaître en profondeur vos films préférés.",
            },
            project4: {
              description1:
                "une application qui donne aux utilisateurs la possibilité d'enregistrer un compte ou de se connecter pour essayer la fonctionnalité sans inscription. Si vous n'avez pas de compte existant, vous pouvez utiliser les informations de test suivantes: e-mail ",

              password: " et mot de passe ",

              description2:
                " L'application vous présente une liste de saisons disponibles. Si vous ne trouvez pas la saison que vous recherchez, vous avez la possibilité d'en créer une nouvelle. À partir de là, vous pouvez gérer vos matchs, en éditant, en créant ou en supprimant des événements associés à chaque saison. Vous pouvez également conserver un historique des résultats de vos événements. Match Event est développé en utilisant des technologies telles que Laravel, Bootstrap, CSS et MySQL.",
            },
            project5: {
              description:
                "est une application interactive qui permet aux utilisateurs de s'inscrire avec leur adresse e-mail, leur nom d'utilisateur et leur nom complet via l'API 'jsonplaceholder.typicode.com', et tire également parti de l'API Star Wars. En plus de simuler la création d'utilisateurs, l'application exploite l'API Star Wars pour afficher des détails fascinants sur les planètes de la saga. La mise en œuvre démontre mes compétences en intégrant plusieurs API, en gérant les données utilisateur et en fournissant une expérience complète et immersive pour l'utilisateur, mettant ainsi en évidence ma compétence technique et créative dans le développement d'applications.",
            },
            project6: {
              description:
                "est une application passionnante en cours de développement qui vous permet de créer votre équipe préférée en utilisant des joueurs de la ligue. Cette plateforme innovante vous offre la possibilité de monter une équipe de rêve avec les meilleurs joueurs de la ligue et de concourir dans des défis virtuels. Préparez-vous à vivre une expérience sportive unique et passionnante alors que cette application continue de se développer.",
            },
            project7: {
              description:
                "J'ai développé un site Web réactif en utilisant React et CSS pour BIO WEB, une entreprise spécialisée dans les services de nettoyage écologiques. Le site Web comprend des sections comprenant Accueil, À propos, Services et Contact, mettant en avant les avantages de l'utilisation de produits bio pour le nettoyage tout en offrant un accès facile aux informations et à la programmation des services.",
            },
            projectList: {
              access: "Accéder à ",
            },
          },

          contact: {
            title: "CONTACT",
            subtitle: "PARLONS?",
            description:
              "Si vous avez des questions ou souhaitez en savoir plus sur moi, n'hésitez pas à me contacter. Je serai ravi de vous répondre!",
            name: "Nom",
            email: "E-mail",
            subject: "Sujet",
            message: "Message",
            send: "Envoyer",
            messageSent: "Message envoyé avec succès.",
            errorMsg:
              "Une erreur s'est produite. Vous pouvez réessayer plus tard ou me contacter par e-mail.",
            emptyfields: "Veuillez remplir tous les champs.",
            invalidEmail: "Veuillez saisir une adresse e-mail valide.",
          },

          footer: {
            title: "© 2024 Ayoub Bouazi",
            subtitle: "Tous droits réservés.",
            contactInfo: "Coordonnées",
            phone: "Téléphone",
            email: "E-mail",
          },
        },
      },
    },
  });

export default i18next;
