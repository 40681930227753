import React from "react";
import { SocialIcon } from "react-social-icons";

const IconSocialMedia = () => {
  return (
    <>
      <SocialIcon network="email" url="mailto:contact@ayoubbouazi.com, ayoub.bouazi@gmail.com" />
      <SocialIcon
        url="https://github.com/bouaziayoub"
        className="social-icon"
        width={20}
      />
      <SocialIcon
        url="https://linkedin.com/in/ayoubbouazi/"
        className="social-icon"
      />
      {/* <SocialIcon
            url="https://api.whatsapp.com/send?phone=34641401566"
            className="social-icon"
          /> */}
    </>
  );
};

export default IconSocialMedia;
