import React, { useState, useEffect, useRef } from "react";
import "./LanguageSelector.css";
import { useTranslation } from "react-i18next";
import { cat, en, es, ar, fr } from "../../assets/images/exportImages";
import i18n from "../../assets/i18/i18n";

const LanguageSelector = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const languageSelectorRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        languageSelectorRef.current &&
        !languageSelectorRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (lng) => {
    i18n
      .changeLanguage(lng)
      .then(() => toggleMenu())
      .catch((error) => console.error("Error changing language:", error));
  };

  const lang = localStorage.getItem("i18nextLng") || "es";

  const getFlagImage = (lang) => {
    switch (lang) {
      case "ar":
        return ar;
      case "en":
        return en;
      case "es":
        return es;
      case "fr":
        return fr;
      case "cat":
        return cat;
      default:
        return null;
    }
  };

  // List of languages available in the app
  const languages = ["ar", "en", "es", "fr", "cat"];
  // Remove the current language from the list of languages
  const filteredLanguages = languages.filter(language => language !== lang);

  return (
    <div
      className={`language-dropdown ${isMenuOpen ? "active" : ""}`}
      ref={languageSelectorRef}
    >
      <button onClick={toggleMenu}>
        <img className="executed-flag" src={getFlagImage(lang)} width={25} alt="Language Flag" />
      </button>
      <ul>
        {filteredLanguages.map((language) => (
          <li
            className="navbar-link"
            key={language}
            onClick={() => changeLanguage(language)}
          >
            <img
              className="flag-icon"
              src={getFlagImage(language)}
              width={25}
              alt={`${language} Flag`}
            />
            <span className="language-text">
              {t(`header.${language.toLowerCase()}`)}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSelector;
