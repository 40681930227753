import React from "react";
import "./WorkList.css";

import { useTranslation } from "react-i18next";

function WorkList({ workItems }) {
  const { t } = useTranslation();

  return (
    <div className="content work-list">
      {workItems.map((work, index) => (
        <React.Fragment key={index}>
          <div className="work-card" key={work.id}>
            <a
              href={`http://${work.link}`}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <div className="work-card-inner">
                <img src={work.imageUrl} alt={work.title} />
                <div className="work-card-text">
                  <h3 className="title-card">{work.title}</h3>
                  <p className="langueges-card">{work.langueges}</p>
                  <p
                    className="langueges-card"
                    style={{ textDecoration: "underline" }}
                  >
                    {t(`projects.projectList.access`)}
                    {work.title}
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="work-card-description description">
            {/* Aquí puedes agregar los detalles adicionales */}
            <p>{work.description}</p>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

export default WorkList;
