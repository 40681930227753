import React, { useState } from "react";
import "./About.css";
import { arrowRight } from "../../assets/images/exportImages";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  const [openCard, setOpenCard] = useState(null);

  const toggleCard = (cardName) => {
    if (openCard === cardName) {
      setOpenCard(null); // If you click on the open card, pull it
    } else {
      setOpenCard(cardName); // Otherwise, open the click card
    }
  };

  const info = {
    studies: [
      <span>
        <b> {t(`aboutMe.education1.title`)} </b>
        {t(`aboutMe.education1.description`)}
      </span>,
      <span>
        <b> {t(`aboutMe.education2.title`)} </b>

        {t(`aboutMe.education2.description`)}
      </span>,
      <span>
        <b> {t(`aboutMe.education3.title`)} </b>
        {t(`aboutMe.education3.description`)}
      </span>,
    ],

    experience: [
      <span>
      <b>{t(`aboutMe.experience0.title`)}</b>{" "}
      {t(`aboutMe.experience0.company`)} {t(`aboutMe.experience0.country`)}{" "}
      {t(`aboutMe.experience0.date`)}
      <br /> <b>{t(`aboutMe.skills`)}</b>{" "}
      {t(`aboutMe.experience0.description`)}
    </span>,
      <span>
        <b>{t(`aboutMe.experience1.title`)}</b>{" "}
        {t(`aboutMe.experience1.company`)} {t(`aboutMe.experience1.country`)}{" "}
        {t(`aboutMe.experience1.date`)}
        <br /> <b>{t(`aboutMe.skills`)}</b>{" "}
        {t(`aboutMe.experience1.description`)}
      </span>,
      <span>
        <b>{t(`aboutMe.experience2.title`)}</b>{" "}
        {t(`aboutMe.experience2.company`)} {t(`aboutMe.experience2.country`)}{" "}
        {t(`aboutMe.experience2.date`)}
        <br /> <b>{t(`aboutMe.skills`)}</b>{" "}
        {t(`aboutMe.experience2.description`)}
      </span>,
      <span>
        <b>{t(`aboutMe.experience3.title`)}</b>{" "}
        {t(`aboutMe.experience3.company`)} {t(`aboutMe.experience3.country`)}{" "}
        {t(`aboutMe.experience3.date`)}
        <br /> <b>{t(`aboutMe.skills`)}</b>{" "}
        {t(`aboutMe.experience3.description`)}
      </span>,
      <span>
        <b>{t(`aboutMe.experience4.title`)}</b>{" "}
        {t(`aboutMe.experience4.company`)} {t(`aboutMe.experience4.country`)}{" "}
        {t(`aboutMe.experience4.date`)}
        <br /> <b>{t(`aboutMe.skills`)}</b>{" "}
        {t(`aboutMe.experience4.description`)}
      </span>,
      <span>
        <b>{t(`aboutMe.experience5.title`)}</b>{" "}
        {t(`aboutMe.experience5.company`)} {t(`aboutMe.experience5.country`)}{" "}
        {t(`aboutMe.experience5.date`)}
        <br /> <b>{t(`aboutMe.skills`)}</b>{" "}
        {t(`aboutMe.experience5.description`)}
      </span>,
    ],
    languages: [
      { name: "HTML/CSS", level: 5 },
      { name: "SASS", level: 5 },
      { name: "JavaScript", level: 5 },
      { name: "ReactJS", level: 5 },
      { name: "AngularJS", level: 3 },

      { name: "Node.js", level: 5 },
      { name: "PHP", level: 4 },
      { name: "Laravel", level: 4 },
      { name: "Java", level: 4 },
      { name: "Spring Boot", level: 4 },

      { name: "MySQL", level: 5 },
      { name: "MongoDB", level: 4 },
      { name: "SQL", level: 4 },
      { name: "Figma", level: 4 },
      { name: "Adobe Illustrator", level: 3 },
    ],
    lang: [
      { name: t(`aboutMe.lang1.title`), level: 5 },
      { name: t(`aboutMe.lang2.title`), level: 5 },
      { name: t(`aboutMe.lang3.title`), level: 4 },
      { name: t(`aboutMe.lang4.title`), level: 4 },
      { name: t(`aboutMe.lang5.title`), level: 4 },
      { name: t(`aboutMe.lang6.title`), level: 2 },
    ],
  };

  // Function for stars rating system (★☆☆☆☆)
  const getStars = (level) => {
    const filledStars = "★".repeat(level);
    const emptyStars = "☆".repeat(5 - level); // Assuming a maximum of 5 stars
    return (
      <span className="stars" style={{ marginLeft: "5px", fontSize: "1.2em" }}>
        {filledStars}
        {emptyStars}
      </span>
    );
  };

  // Function for level text
  const getLevelText = (level) => {
    const levelTexts = {
      1: t(`levelLang.basic`),
      2: t(`levelLang.intermediate`),
      3: t(`levelLang.advanced`),
      4: t(`levelLang.fluent`),
      5: t(`levelLang.native`),
    };

    return levelTexts[level] || "N/A";
  };
  return (
    <div className="my-container content" id="about">
      <h1 className="title"> {t(`aboutMe.title`)}</h1>
      <div className="container-about">
        <p className="description about">
          {t(`aboutMe.firstDescription`)}
          <br />
          <br /> {t(`aboutMe.secondDescription`)}
          <br />
          <br /> {t(`aboutMe.thirdDescription`)}
        </p>
        <div
          className={`info-card ${openCard === "experience" ? "open" : ""}`}
          onClick={() => toggleCard("experience")}
        >
          <h3> {t(`aboutMe.experienceTitle`)} </h3>
          {openCard === "experience" && (
            <ul>
              {info.experience.map((experience, index) => (
                <li key={index}>
                  <img src={arrowRight} alt="arrowRight" width={"15px"} />{" "}
                  {experience}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div
          className={`info-card ${openCard === "studies" ? "open" : ""}`}
          onClick={() => toggleCard("studies")}
        >
          <h3>{t(`aboutMe.educationTitle`)}</h3>
          {openCard === "studies" && (
            <ul>
              {info.studies.map((study, index) => (
                <li key={index}>
                  <img src={arrowRight} alt="arrowRight" width={"15px"} />{" "}
                  {study}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div
          className={`info-card ${openCard === "languages" ? "open" : ""}`}
          onClick={() => toggleCard("languages")}
        >
          <h3>{t(`aboutMe.skillsTitle`)}</h3>
          {openCard === "languages" && (
            <ul className="language-list">
              {info.languages.map((language, index) => (
                <li key={index}>
                  <img src={arrowRight} alt="arrowRight" width={"15px"} />{" "}
                  <span className="language-info">
                    <span className="language-name">{language.name}</span>{" "}
                    <span className="language-level">
                      {getStars(language.level)}
                    </span>
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div
          className={`info-card ${openCard === "lang" ? "open" : ""}`}
          onClick={() => toggleCard("lang")}
        >
          <h3>{t(`aboutMe.langTitle`)}</h3>
          {openCard === "lang" && (
            <ul className="language-list">
              {info.lang.map((language, index) => (
                <li key={index}>
                  <img src={arrowRight} alt="arrowRight" width={"15px"} />{" "}
                  <span className="language-info">
                    <span className="language-name">{language.name}</span>{" "}
                    <span className="language-level">
                      {getLevelText(language.level)}
                    </span>
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
