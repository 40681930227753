import React from "react";
import { PdfViewer } from "../exportComponents";
import "./FloatingButton.css";

const FloatingButton = () => {
  return (
    <div className="floating-button">
      <PdfViewer />
    </div>
  );
};

export default FloatingButton;
