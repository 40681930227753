import React, { useState, useEffect } from "react";
import "./Home.css";
import { profile } from "../../assets/images/exportImages";
import { FloatingButton } from "../../components/exportComponents";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  const [text, setText] = useState("");
  const [isFrontend, setIsFrontend] = useState(true);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const typeText = () => {
      // const word = isFrontend ? "FULLSTACK DEVELOPER" : "UX/UI DESIGNER";
      const word = isFrontend ? t(`home.roleFullstack`) : t(`home.roleUI`);

      if (isTyping) {
        if (text.length < word.length) {
          setText((prevText) => prevText + word.charAt(prevText.length));
        } else {
          setIsTyping(false);
          setTimeout(() => {
            setIsTyping(true);
            setIsFrontend((prevIsFrontend) => !prevIsFrontend);
            setText("");
          }, 2000);
        }
      }
    };

    const interval = setInterval(typeText, 100);

    return () => {
      clearInterval(interval);
    };
  }, [text, isTyping, isFrontend, t]);

  return (
    <>
      <div className="my-container" id="home">
        <div className="myPic">
          <img src={profile} alt="" />
        </div>
        <div className="content">
          <h1 className="title animate__animated animate__fadeInDown">
            {/* ayoub bouazi */}
            {t(`home.title`)}
          </h1>
          <span className="subtitle animate__animated animate__fadeIn">
            {/* soy: <span className="role">{text}</span> */}
            {t(`home.subtitle`)} <span className="role">{text}</span>
          </span>
          <p className="description animate__animated animate__fadeInUp">
            {/* Con experiencia en diversas tecnologías tanto en el frontend como en el backend, incluyendo MySQL y MongoDB. Actualmente, estoy trabajando en un proyecto personal utilizando Node.js, React.js y MySQL, lo que me ha brindado experiencia adicional y habilidades prácticas en el desarrollo de aplicaciones web. */}
            {t(`home.description`)}
          </p>
        </div>
        <FloatingButton />
      </div>
    </>
  );
};

export default Home;
