import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-scroll";
// import logo from "../../assets/images/logo.png";
import { logo } from "../../assets/images/exportImages";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconSocialMedia, LanguageSelector } from "../exportComponents";
import { LampSwitch } from "../exportComponents";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();

  // this is for the hamburger menu
  const [spin, setSpin] = useState(false);

  const [slideRight, setSlideRight] = useState(false);

  const [activeSection] = useState("");
  // const [menuOpen, setMenuOpen] = useState(false);

  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
    // Toggle the class on the body element to disable scrolling
    document.body.classList.add("no-scroll");
    setSpin(true); // Start the animation
    setSlideRight(true);

    setTimeout(() => {
      setSpin(false);
      setSlideRight(false);
    }, 500); // 500ms is the duration of the animation
  };
  const closeMenu = () => {
    setClick(false);
    document.body.classList.remove("no-scroll");
  };

  return (
    <div className={`navbar-container ${slideRight ? "slide-right" : ""}`}>
      <Link
        to="home"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
        className="navbar-logo"
      >
        <img src={logo} alt="logo" className="logo" />
      </Link>
      <div className={`hamburger ${spin ? "spin" : ""}`} onClick={handleClick}>
        {click ? (
          <FaTimes size={30} style={{ backgroundColor: "transparent" }} />
        ) : (
          <FaBars size={30} style={{ backgroundColor: "transparent" }} />
        )}
      </div>

      <ul className={`navbar-menu ${click ? "active" : ""}`}>
        <li className={`menu-items ${slideRight ? "slide-right" : ""}`}>
          <Link
            to="home"
            spy={true}
            smooth={true}
            offset={0}
            duration={300}
            className={`navbar-link ${
              activeSection === "home" ? "active" : ""
            }`}
            onClick={closeMenu}
          >
            {/* translation home */}
            {t(`header.home`)}
          </Link>
        </li>
        <li className={`menu-items ${slideRight ? "slide-right" : ""}`}>
          <Link
            to="about"
            spy={true}
            smooth={true}
            offset={0}
            duration={300}
            className={`navbar-link ${
              activeSection === "about" ? "active" : ""
            }`}
            onClick={closeMenu}
          >
            {/* translation aboutMe */}
            {t(`header.aboutMe`)}
          </Link>
        </li>
        <li className={`menu-items ${slideRight ? "slide-right" : ""}`}>
          <Link
            to="works"
            spy={true}
            smooth={true}
            offset={0}
            duration={300}
            className={`navbar-link ${
              activeSection === "works" ? "active" : ""
            }`}
            onClick={closeMenu}
          >
            {/* translation projects */}
            {t(`header.projects`)}
          </Link>
        </li>
        <li className={`menu-items ${slideRight ? "slide-right" : ""}`}>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={0}
            duration={300}
            className={`navbar-link ${
              activeSection === "contact" ? "active" : ""
            }`}
            onClick={closeMenu}
          >
            {/* translation contact */}
            {t(`header.contact`)}
          </Link>
        </li>
        <li className={`menu-items ${slideRight ? "slide-right" : ""}`}>
          <LanguageSelector />
        </li>

        <div className="hide-socialmedia">
          {/* <li>Teléfono: +34 641 401 566</li>
          <li>Email: contact@ayoubbouazi.com</li>
           */}
          <div
            className={`social-icons menu-items ${
              slideRight ? "slide-right" : ""
            }`}
          >
            <IconSocialMedia />
            <LampSwitch />
          </div>
        </div>

        <li className={`lampe-switch menu-items ${slideRight ? "slide-right" : ""}`}>
          <LampSwitch />
        </li>
      </ul>
    </div>
  );
};

export default Header;
