import React from "react";
import "./Works.css";
import { WorkList } from "../../components/exportComponents";

import { useTranslation } from "react-i18next";

//? SIZE PIC: 620x390 esta editado.. aqui : https://www.img2go.com/resize-image

import {
  inmova,
  liga,
  scanorder,
  startWars,
  matchEvents,
  movieInfo,
  webBio,
} from "../../assets/images/exportImages";
const Works = () => {
  const { t } = useTranslation();

  const works = [
    {
      title: "Scanurorder",
      langueges: "ReactJs - Css - Bootstrap - NodeJs - MySQL  ",
      imageUrl: scanorder,
      link: "scanurorder.netlify.app",
      description: (
        <span>
          <b> Scanurorder</b> {t(`projects.project1.description`)}
          <b>test@gmail.com</b> {t(`projects.project1.password`)}
          <b>123456aA*</b>
        </span>
      ),
    },
    {
      title: "Web Bio",
      langueges: "ReactJs - Css ",
      imageUrl: webBio,
      link: "web-bio-company.netlify.app",
      description: (
        <span>
          <b> Web Bio</b> {t(`projects.project7.description`)}
        </span>
      ),
    },
    {
      title: "Inicio inmovalley",
      langueges: "HTML - SASS - Bootstrap - Js - jQuery",
      imageUrl: inmova,
      link: "bouaziayoub.github.io/home-inmovalley",
      description: (
        <span>
          <b> {t(`projects.project2.title`)}</b>
          {t(`projects.project2.describe`)}
          <a href="https://inmovalley.com" target="_">
            inmovalley
          </a>
          {t(`projects.project2.description`)}
        </span>
      ),
    },
    {
      title: "Movie Info",
      langueges: "React - CSS -  API (TMDb)",
      imageUrl: movieInfo,
      link: "bouaziayoub.github.io/movie-info/",
      description: (
        <span>
          <b> Movie info </b>
          {t(`projects.project3.description`)}
        </span>
      ),
    },
    {
      title: "Match Event",
      langueges: "Laravel - Bootstrap - CSS - MySQL",
      imageUrl: matchEvents,
      link: "matchevent.wuaze.com/",
      description: (
        <span>
          <b> Match event </b>
          {t(`projects.project4.description1`)}
          <b>test@gmail.com </b>
          {t(`projects.project4.password`)}
          <b>test123</b>.{t(`projects.project4.description2`)}
        </span>
      ),
    },
    {
      title: "La Liga",
      langueges: "HTML - CSS - Javascript -  API ( football )",
      imageUrl: liga,
      link: "bouaziayoub.github.io/la-liga/",
      description: (
        <span>
          <b>La Liga</b> {t(`projects.project6.description`)}
        </span>
      ),
    },
    {
      title: "The Star Wars",
      langueges: "HTML - CSS - Javascript - Api( jsonplaceholder )",
      imageUrl: startWars,
      link: "bouaziayoub.github.io/the-start-wars/",
      description: (
        <span>
          <b>The Star Wars</b> {t(`projects.project5.description`)}
        </span>
      ),
    },

    // Add more work items as needed
  ];
  return (
    <div className="my-container content" id="works">
      {/* <h1 className="centered-text">PROYECTOS [EN PROCESO...]</h1> */}
      <h1 className="title">{t(`projects.title`)}</h1>
      <h2> {t(`projects.description`)} </h2>
      <div className="">
        <WorkList workItems={works} />
      </div>
      <br />
      {/* <br /> */}
      <br />
      <h3 style={{ textTransform: "uppercase" }}>{t(`projects.subtitle`)}</h3>
    </div>
  );
};

export default Works;
