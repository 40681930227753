import React, { useState, useEffect, useRef } from "react";
import "./SocialMedia.css";
import { IconSocialMedia } from "../exportComponents";
import { useTranslation } from "react-i18next";

const SocialMedia = () => {
  const [showIcons, setShowIcons] = useState(false);
  const { t } = useTranslation();
  const socialMediaRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Verifica si el clic ocurrió fuera del componente SocialMedia
      if (
        socialMediaRef.current &&
        !socialMediaRef.current.contains(event.target)
      ) {
        setShowIcons(false);
      }
    };

    const handleScroll = () => {
      // Cierra el menú durante el scroll
      setShowIcons(false);
    };

    // Agrega event listener al documento para clics fuera del componente
    document.addEventListener("mousedown", handleOutsideClick);

    // Agrega event listener al documento para el evento de scroll
    document.addEventListener("scroll", handleScroll);

    // Limpia los event listeners cuando el componente se desmonta
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []); // La dependencia vacía asegura que el effect se ejecute solo una vez al montar el componente

  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };

  return (
    <>
      <div className={`socialMedia ${showIcons ? "active" : ""}`} ref={socialMediaRef}>
        {showIcons && <IconSocialMedia />}
      </div>
      <button className="social-button" onClick={toggleIcons}>
        {t("home.socialMedia")}
      </button>
    </>
  );
};

export default SocialMedia;
