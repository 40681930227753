import React from "react";
import "./Footer.css";
import { IconSocialMedia } from "../exportComponents";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div dir="auto" className="footer">
      <div className="contact-info">
        <h3>{t(`footer.contactInfo`)}</h3>
        {/* <p>{t(`footer.phone`)} +34 641 401 566</p> */}
        <p>ayoub.bouazi@gmail.com</p>
        {/* <p>contact@ayoubbouazi.com</p> */}
      </div>
      <div className="social-icons">
        <IconSocialMedia />
      </div>
      <p>
        {t(`footer.title`)}
        <br />
        {t(`footer.subtitle`)}
      </p>
    </div>
  );
};

export default Footer;
